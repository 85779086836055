import React from "react";
import "../css/AboutUs.scss";
import { Container } from "react-bootstrap";

const AboutUs = () => {
  return (
    <Container fluid className="about_container" id="about_page">
      <div className="text-center container">
        <h1 className="section-heading text-uppercase">About Us</h1>
        <hr className="about-divider mt-2 mb-4" />
        <h3 className="about-content">We are a dedicated group of young, yet experienced group of professionals providing services such as Banking Advisory Services, Financial Consulting Services, Audit and Assurance, Accounting and Taxation Services, CFO/Controller Services, Business Set-up Consultancy and Business Consultancy Services. We always try to exceed the client expectations and we believe this is our mission.</h3>
        <h3 className="about-content mt-4">Most of our new clients have come to us from referrals of existing client relationships and we value client relationships more than anything else - only because we need to grow along with the growth of our client.</h3>
      </div>
    </Container>
  );
};

export default AboutUs;
