export const Constants = {
  services: [
    {
      id: 1,
      service_name: "Business Registration",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "1",
              name: "Private Limited Company",
            },
            {
              id: "2",
              name: "Limited Liability Partnership (LLP)",
            },
            {
              id: "3",
              name: "Foreign Subsidiary",
            },
            {
              id: "4",
              name: "One Person Company (OPC)",
            },
            {
              id: "5",
              name: "Public Limited Company",
            },
            {
              id: "6",
              name: "Partnership Firm",
            },
            {
              id: "7",
              name: "Sole Proprietorship",
            },
            {
              id: "8",
              name: "Non-Banking Finance Company (NBFC)",
            },
            {
              id: "9",
              name: "Charitable Company (Section 8)",
            },
            {
              id: "10",
              name: "Nidhi Company",
            },
            {
              id: "11",
              name: "Chit Fund Company",
            },
            {
              id: "12",
              name: "Producer Company",
            },
            {
              id: "13",
              name: "Startup Registration",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      service_name: "Business Licensing",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "14",
              name: "Permanent Account Number (PAN)",
            },
            {
              id: "15",
              name: "Tax Account Number (TAN)",
            },
            {
              id: "16",
              name: "GST Registration (Individuals)",
            },
            {
              id: "17",
              name: "GST Registration (LLP)",
            },
            {
              id: "18",
              name: "GST Registration (Companies)",
            },
            {
              id: "19",
              name: "GST Registration (Others)",
            },
            {
              id: "20",
              name: "GST Registration (Temporary)",
            },
            {
              id: "21",
              name: "GST Cancellation",
            },
            {
              id: "22",
              name: "Profession Tax (PTEC/PTRC)",
            },
            {
              id: "23",
              name: "Import Export Code Registration (IEC)",
            },
            {
              id: "24",
              name: "FSSAI Registration",
            },
            {
              id: "25",
              name: "MSME Registration",
            },
            {
              id: "26",
              name: "Shop Act Registration",
            },
            {
              id: "27",
              name: "Digital Signature",
            },
            {
              id: "28",
              name: "Udyam Registration",
            },
            {
              id: "29",
              name: "Other Licenses",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      service_name: "Business Compliance",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "30",
              name: "Name Change",
            },
            {
              id: "31",
              name: "Registered Office Change",
            },
            {
              id: "32",
              name: "Add Director",
            },
            {
              id: "33",
              name: "Remove Director",
            },
            {
              id: "34",
              name: "Share Transfer",
            },
            {
              id: "35",
              name: "MOA Amendment",
            },
            {
              id: "36",
              name: "Partnership Changes",
            },
            {
              id: "37",
              name: "FSSAI Registration",
            },
            {
              id: "38",
              name: "ESI Return Filing",
            },
            {
              id: "39",
              name: "Annual Company Filing",
            },
            {
              id: "40",
              name: "Annual LLP Filing",
            },
            {
              id: "41",
              name: "Closing Company",
            },
            {
              id: "42",
              name: "Closing LLP",
            },
            {
              id: "43",
              name: "Increasing Authorized Capital",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      service_name: "Business Legal Services",
      categories: [
        {
          category_name: "Trademark",
          service_items: [
            {
              id: "44",
              name: "Trademark Registration",
            },
            {
              id: "45",
              name: "Trademark Opposition",
            },
            {
              id: "46",
              name: "Trademark Assignment",
            },
            {
              id: "47",
              name: "Trademark Renewal",
            },
          ],
        },
        {
          category_name: "Copyright",
          service_items: [],
        },
        {
          category_name: "Patent",
          service_items: [
            {
              id: "49",
              name: "Patent Search",
            },
            {
              id: "50",
              name: "Provisional Patent",
            },
            {
              id: "51",
              name: "Permanent Patent",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      service_name: "Valuation & Funding Advisory",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "52",
              name: "Startup Valuation",
            },
            {
              id: "53",
              name: "Company Valuation",
            },
            {
              id: "54",
              name: "Business Plan",
            },
            {
              id: "55",
              name: "CMA Reporting",
            },
            {
              id: "56",
              name: "Bank Loan Rating",
            },
            {
              id: "57",
              name: "Credit Score Check",
            },
            {
              id: "58",
              name: "Term Loan",
            },
            {
              id: "59",
              name: "Working Capital",
            },
            {
              id: "60",
              name: "Bank Loan Syndication",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      service_name: "Banking Services",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "61",
              name: "Loan Assist",
            },
            {
              id: "62",
              name: "Book Keeping",
            },
            {
              id: "63",
              name: "Project Loans",
            },
            {
              id: "64",
              name: "Loan Takeovers",
            },
            {
              id: "65",
              name: "Overdraft Facility",
            },
          ],
        },
      ],
    },
    {
      id: 7,
      service_name: "Credit Ratings",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "66",
              name: "Corporate",
            },
            {
              id: "67",
              name: "Financial Institutions",
            },
            {
              id: "68",
              name: "Mutual Fund",
            },
            {
              id: "69",
              name: "Public Finance",
            },
            {
              id: "70",
              name: "Structured Finance",
            },
            {
              id: "71",
              name: "Infrastructure & Project Finance",
            },
          ],
        },
      ],
    },
    {
      id: 8,
      service_name: "Accounting",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "72",
              name: "Accounting Packages",
            },
            {
              id: "73",
              name: "GST Return Filing",
            },
            {
              id: "74",
              name: "Income Tax Return Filing",
            },
            {
              id: "75",
              name: "TDS Return Filing",
            },
            {
              id: "76",
              name: "GST Annual Return",
            },
            {
              id: "77",
              name: "GST Refund Services",
            },
            {
              id: "78",
              name: "GST LUT Application",
            },
          ],
        },
      ],
    },
    {
      id: 9,
      service_name: "Auditing",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "79",
              name: "Statutory Audit",
            },
            {
              id: "80",
              name: "Payroll Audit",
            },
            {
              id: "81",
              name: "Revenue Audit",
            },
            {
              id: "82",
              name: "Internal Audit",
            },
            {
              id: "83",
              name: "GST Audit",
            },
          ],
        },
      ],
    },
    {
      id: 10,
      service_name: "Web & Software Solutions",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "84",
              name: "Website Development",
            },
            {
              id: "85",
              name: "ERP Software Development",
            },
            {
              id: "86",
              name: "Accounting Software Development",
            },
            {
              id: "87",
              name: "Healthcare Software Development",
            },
            {
              id: "88",
              name: "Other Management Software",
            },
          ],
        },
      ],
    },
    {
      id: 11,
      service_name: "Management Consulting",
      categories: [
        {
          category_name: "",
          service_items: [
            {
              id: "89",
              name: "Project Management Services",
            },
            {
              id: "90",
              name: "Due Diligence",
            },
            {
              id: "91",
              name: "Compliance Outsourcing",
            },
            {
              id: "92",
              name: "Comparative Financial Analysis",
            },
          ],
        },
      ],
    },
  ],
};
