import axios from "axios";
import React, { useRef, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import "../css/ContactUs.scss";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [mailStatus, setMailStatus] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const API_PATH = "contact/mailer.php";
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form_data = new FormData();
    form_data.append("name", name);
    form_data.append("email", email);
    form_data.append("phoneNumber", phoneNumber);
    form_data.append("message", message);
    axios
      .post(`${API_PATH}`, form_data)
      .then((response) => {
        if (response) {
          setName("");
          setMessage("");
          setPhoneNumber("");
          setEmail("");
          setMailStatus(response.data);
          setModalHeader("Success!");
          handleShow();
        } else {
          setModalHeader("Error!");
          setMailStatus(
            "Oops! Something went wrong and we couldn't send your message."
          );
        }
      })
      .catch((error) => {
        console.log("Mail error", error);
        setModalHeader("Error!");
        setMailStatus(
          "Oops! Something went wrong and we couldn't send your message."
        );
      });
  };
  return (
    <Container fluid className="contactus_container" id="contact_page">
      <div className="container">
        <h1>CONTACT US</h1>
        <div className="contact_divider"></div>
        <Row>
          <p className="text-muted mb-5 contact-info">
            Where does your business need more support? We offer a broad range
            of services and packages that be customized to your needs. Send us a
            message today, and we can start you on a path to success.
          </p>
          <Col sm={12} md={6}>
            <form
              className="contact-form"
              action="#"
              onSubmit={handleFormSubmit}
            >
              <div className="contact-input-container">
                <label>Name *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your name.."
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="contact-input-container">
                <label>Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={email}
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="contact-input-container">
                <label>Phone Number</label>
                <input
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="Your Phone Number(Optional)"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="contact-input-container">
                <label>Message *</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  value={message}
                  placeholder="Write something.."
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <input type="submit" value="Submit" />
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>{modalHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{mailStatus}</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </form>
          </Col>
          <Col sm={12} md={6}>
            <div className="contact-mobile">
              <h4 className="mt-0 address">
                Capsys Business Solutions Pvt Ltd
              </h4>
              <h6 className="mt-0 address">7/431-CB1, Narayana Apartments,</h6>
              <h6 className="mt-0 address">Kunnumpuram, Civil Lane Road,</h6>
              <h6 className="mt-0 address">Thazhvaram Lane, Kakkanad,</h6>
              <h6 className="mt-0 address">Ernakulam - 682030</h6>
              <h6 className="mt-0 address">Kerala, India</h6>
            </div>
            <hr className="my-4" />
            <Row>
              <Col>
                <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                <div className="contact-value">+91 8547726755</div>
              </Col>
              <Col>
                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                <a
                  className="d-block contact-value"
                  href="mailto:support@capsys.co.in"
                >
                  support@capsys.co.in
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ContactUs;
