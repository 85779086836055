import React, { useEffect } from "react";
import Header from "./Header";
import Services from "./Services";
import { Container, Jumbotron } from "react-bootstrap";
import "../css/Home.scss";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import ImageSlides from "./ImageSlides";

const Home = () => {

  return (
    <Container fluid className="m-0 p-0" id="main_app_container">
      <Header></Header>
      <ImageSlides></ImageSlides>
      <AboutUs></AboutUs>
      <Services></Services>
      <ContactUs></ContactUs>
      <Footer></Footer>
    </Container>
  );
};

export default Home;
