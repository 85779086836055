import React from "react";
import { Container, Row, Col, Card, CardGroup } from "react-bootstrap";
import "../css/Services.scss";
import { Constants } from "../utils/Constants";


const Services = () => {
  return (
    <Container className="services_container pb-4 pl-0 pr-0" id="service_page">
      <h1 className="mt-5 mb-2 text-center">SERVICES</h1>
      <div className="service-divider"></div>
      <Row gutter={16}>
        <CardGroup>
          {Constants.services.map((service, index) => (
            <Col className="mb-4" xs={12} sm={6} md={4} key={index}>
              {/*   <div class="timeline-image">
                <span class="service-index">
                  <b>{index + 1}</b>
                </span>
              </div> */}
              <Card>
                <Card.Header className="text-left" as="h5">
                  {service.service_name}
                </Card.Header>
                <div className="item-divider"></div>
                <Card.Body className="text-left">
                  {service.categories.map((category, index) => (
                    <div key={index}>
                      {category.category_name ? (
                        <Card.Text
                          className="font-weight-bold sub-head"
                          key={index}
                        >
                          {category.category_name}
                        </Card.Text>
                      ) : (
                        ""
                      )}
                      {category.service_items.map((serviceItem, index) => (
                        <Card.Text className="mb-2 ml-1" key={index}>
                          - {serviceItem.name}
                        </Card.Text>
                      ))}
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </CardGroup>
      </Row>
      {/*  <Row>
        {Constants.services.map((service, index) => (
          <Col xs={6} md={4}>
            
            <h3>{service.service_name}</h3>
            {service.categories.map((category, index) => (
              <div>
                <b>{category.category_name}</b>
                {category.service_items.map((serviceItem, index) => (
                  <p>{serviceItem.name}</p>
                ))}
              </div>
            ))}
          </Col>
        ))}
      </Row> */}
    </Container>
  );
};

export default Services;
