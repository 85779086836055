import React from "react";
import { Carousel } from "react-bootstrap";
import "../css/ImageSlides.scss";
import { Constants } from "../utils/Constants";
import carousel1 from '../assets/images/accounting.jpg';
import carousel2 from '../assets/images/banking.jpg';
import carousel3 from '../assets/images/registration.jpg';
import carousel4 from '../assets/images/software.jpg';
import carousel5 from '../assets/images/consulting.jpg';
import HeroText from "./HeroText";

const ImageSlides = () => {
    const getServices = (id) => {
        let categories = Constants.services.find(service => service.id == id)?.categories;
        let servicesList = [];
        categories.forEach(category => {
            servicesList = [...category.service_items];
        });
        return servicesList;
    }
    return (
        <Carousel id="home_page">
            <Carousel.Item className="image-overlay">
                <img
                    className="d-block w-100 img-carousel"
                    src={carousel1}
                    alt="Accounting"
                />
                <Carousel.Caption className="d-flex flex-column h-100">
                    <HeroText></HeroText>
                    <div className="flex-1">
                        <h3>Accounting</h3>
                        <div className="d-flex flex-wrap justify-content-center pt-2 pb-4">
                            {getServices(8).map((service, index) => (
                                <div key={index} className="px-3">{service.name}</div>
                            ))}
                        </div></div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="image-overlay">
                <img
                    className="d-block w-100 img-carousel"
                    src={carousel2}
                    alt="Banking Services"
                />

                <Carousel.Caption className="d-flex flex-column h-100">
                    <HeroText></HeroText>
                    <div className="flex-1">
                        <h3>Banking Services</h3>
                        <div className="d-flex flex-wrap justify-content-center pt-2 pb-4">
                            {getServices(6).map((service, index) => (
                                <div key={index} className="px-3">{service.name}</div>
                            ))}
                        </div></div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="image-overlay">
                <img
                    className="d-block w-100 img-carousel"
                    src={carousel3}
                    alt="Business Registrations"
                />

                <Carousel.Caption className="d-flex flex-column h-100">
                    <HeroText></HeroText>
                    <div className="flex-1">
                        <h3>Business Registrations</h3>
                        <div className="d-flex flex-wrap justify-content-center pt-2 pb-4">
                            {getServices(1).map((service, index) => (
                                <div key={index} className="px-3">{service.name}</div>
                            ))}
                        </div></div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="image-overlay">
                <img
                    className="d-block w-100 img-carousel"
                    src={carousel4}
                    alt="Web & Software Solutions"
                />

                <Carousel.Caption className="d-flex flex-column h-100">
                    <HeroText></HeroText>
                    <div className="flex-1">
                        <h3>Web & Software Solutions</h3>
                        <div className="d-flex flex-wrap justify-content-center pt-2 pb-4">
                            {getServices(10).map((service, index) => (
                                <div key={index} className="px-3">{service.name}</div>
                            ))}
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="image-overlay">
                <img
                    className="d-block w-100 img-carousel"
                    src={carousel5}
                    alt="Management Consulting"
                />

                <Carousel.Caption className="d-flex flex-column h-100">
                    <HeroText></HeroText>
                    <div className="flex-1">
                        <h3>Management Consulting</h3>
                        <div className="d-flex flex-wrap justify-content-center pt-2 pb-4">
                            {getServices(11).map((service, index) => (
                                <div key={index} className="px-3">{service.name}</div>
                            ))}
                        </div></div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default ImageSlides;
