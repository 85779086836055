import React from "react";
import { Container } from "react-bootstrap";
import "../css/HeroText.scss";
import Link from "react-scroll/modules/components/Link";

const HeroText = () => {

    return (
        <Container className="hero-text-container flex-2" fluid >
            <h3 className="mb-3">EXPERIENCED, PERSONAL CONSULTING</h3>
            <h1 className="mb-5">HELPING YOU DESIGN SUCCESS!</h1>
            <div>
                <Link
                    to="service_page"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    className="mr-2 activeLink tell_me_btn"
                    id="service_link"
                >
                    TELL ME MORE
                </Link>
            </div>
        </Container>

    );
};

export default HeroText;
