import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import logoImage from "../assets/images/navbar-logo.svg";
import "../css/Header.scss";
import { Link } from "react-scroll";

const Header = () => {
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position === 0) {
      addOrRemoveClass("home_link", "active", false);
    } else {
      addOrRemoveClass("home_link", "active", true);
    }
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      // you're at the bottom of the page
      setTimeout(() => {
        addOrRemoveClass("about_link", "active", true);
      }, 500);
      addOrRemoveClass("contact_link", "active", false);
    } else {
      addOrRemoveClass("contact_link", "active", true);
    }
  };

  useEffect(() => {
    const position = window.pageYOffset;
    if (position === 0) {
      const home = document.getElementById("home_link");
      if (home && !home.classList.contains("active")) {
        home.classList.add("active");
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const addOrRemoveClass = (id, className, isRemove) => {
    const element = document.getElementById(id);
    if (isRemove) {
      if (element && element.classList.contains(className)) {
        element.classList.remove(className);
      }
    } else {
      if (element && !element.classList.contains(className)) {
        element.classList.add(className);
      }
    }
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      className="header_container fixed-top"
    >
      <Link
        to="home_page"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        className="mr-2 activeLink link_home"
      >
        <img
          src={logoImage}
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Link
            to="home_page"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="mr-3 activeLink"
            id="home_link"
          >
            HOME
          </Link>
          <Link
            to="about_page"
            spy={true}
            smooth={true}
            offset={-66}
            duration={500}
            className="mr-3 activeLink"
            id="about_link"
          >
            ABOUT US
          </Link>
          <Link
            to="service_page"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="mr-3 activeLink"
            id="service_link"
          >
            SERVICES
          </Link>
          <Link
            to="contact_page"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="activeLink"
            id="contact_link"
          >
            CONTACT US
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
