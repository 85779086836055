import React from "react";
import { Container } from "react-bootstrap";
import "../css/Footer.scss";

const Footer = () => {
  return (
    <Container fluid className="footer_container p-2">
      <span>
        Copyright © Capsys Business Solutions Pvt Ltd - All Rights Reserved.
      </span>
    </Container>
  );
};

export default Footer;
